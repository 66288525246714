import React from "react"
import { FaHome, FaCog, FaDatabase, FaListUl, FaCheck, FaFileUpload, FaPhoneAlt, FaUser, FaRegBuilding, FaUserCog } from "react-icons/fa"
const user = JSON.parse(sessionStorage.getItem('user'))
let size = 15

const navigationSuperadminTop = [
  {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "formconfig",
    title: "Setting",
    type: "collapse",
    icon: <FaCog size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formugoals",
        title: "Goals",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/goalsform"
      }
    ]
  },
  {
    id: "formuser",
    title: "Users",
    type: "collapse",
    icon: <FaUserCog size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formuser1",
        title: "User Form",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/userform"
      },
      {
        id: "formuser2",
        title: "Report",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportlogin"
      }
    ]
  },
  {
    id: "formomabacare",
    title: "Obama care",
    type: "collapse",
    icon: <FaListUl size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesreport1",
        title: "New sale",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/newappointmentsell"
      }
    ]
  },
  {
    id: "formagency",
    title: "Agency",
    type: "collapse",
    icon: <FaRegBuilding size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formagency1",
        title: "Agency registration",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/formagency"
      }
    ]
  },
  {
    id: "formagent",
    title: "Agent",
    type: "collapse",
    icon: <FaUser size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formagent1",
        title: "Register",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/formagent"
      }
    ]
  },
  {
    id: "upload",
    title: "Upload",
    type: "collapse",
    icon: <FaFileUpload size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "uploadagent",
        title: "File agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/uploadagent"
      },
      {
        id: "agentupload",
        title: "Agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/agentupload"
      }
    ]
  },
  {
    id: "salesreport",
    title: "Sales Report",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "reportappointmentsales1",
        title: "Sales in processing",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/prenewappointmentsell"
      },
      {
        id: "salesreport2",
        title: "Pending sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsell"
      },
      {
        id: "salesreport3",
        title: "Closed sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/appointmentsale"
      },
      {
        id: "salesreport4",
        title: "Delete sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/deleteAppointmentsell"
      },
      {
        id: "salesreport5",
        title: "Recovery sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/recoveryappointmentsalesproccesor"
      },
    ]
  },
  {
    id: "reportbackofice",
    title: "Report BackOffice",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "reportSpeakEnglish",
        title: "Speak English",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/speak"
      },
      {
        id: "reportOver65YearsOld",
        title: "Over 65 years old",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/over"
      },
      {
        id: "reportNotInterested",
        title: "Not interested",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/notinterested"
      },
      {
        id: "reportIncorrectNumbers",
        title: "Incorrect or invalid numbers",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/incorrectnumbers"
      },
      {
        id: "reportHasworkinsurance",
        title: "Has Work Insurance",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/hasworinsurance"
      },
      {
        id: "reportNoanswer",
        title: "No answer",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/noanswe"
      }
    ]
  },
  {
    id: "statusfinishsales",
    title: "Status Finish Sales",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "statusfinishsales1",
        title: "Terminated",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesop"
      },
      {
        id: "reportstatusfinishsales2",
        title: "Cancelled",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesca"
      },
      {
        id: "reportstatusfinishsales3",
        title: "Actived",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesac"
      },
    ]
  },
  {
    id: "statusfinishsalesmonth",
    title: "Report Months",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "statusfinishsales1",
        title: "List",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesmonth"
      },
    ]
  },
  {
    id: "salesOthers",
    title: "Sales Others",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "InProccessing",
        title: "In Proccessing",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/InProcessingOther"
      },
      {
        id: "Pending",
        title: "Pending",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/PendingOther"
      },
      {
        id: "ClosedOther",
        title: "Closed",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/ClosedOther"
      },
      {
        id: "Delete",
        title: "Delete",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/DeleteOther"
      },
    ]
  },
]

const navigationAuditor = [
  {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  /* {
    id: "formomabacare",
    title: "Obama care",
    type: "collapse",
    icon: <FaListUl size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesreport1",
        title: "New sale",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/newappointmentsell"
      }
    ]
  }, */
  {
    id: "salesreport",
    title: "Sales Report",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "reportappointmentsales0",
        title: "Sales in prenew",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/prenewappointmentauditor"
      },
      /* {
        id: "reportappointmentsales1",
        title: "Sales in processing",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/prenewappointmentsell"
      }, */
      {
        id: "salesreport2",
        title: "Pending sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsell"
      },
      {
        id: "salesreport3",
        title: "Closed sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/appointmentsale"
      },
      {
        id: "salesreport4",
        title: "Delete sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/deleteAppointmentsell"
      },
      {
        id: "salesreport5",
        title: "Recovery sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/recoveryappointmentsalesproccesor"
      },
    ]
  },
  {
    id: "reportbackofice",
    title: "Report BackOffice",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "reportSpeakEnglish",
        title: "Speak English",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/speak"
      },
      {
        id: "reportOver65YearsOld",
        title: "Over 65 years old",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/over"
      },
      {
        id: "reportNotInterested",
        title: "Not interested",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/notinterested"
      },
      {
        id: "reportIncorrectNumbers",
        title: "Incorrect or invalid numbers",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/incorrectnumbers"
      },
      {
        id: "reportHasworkinsurance",
        title: "Has Work Insurance",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/hasworinsurance"
      },
      {
        id: "reportNoanswer",
        title: "No answer",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/noanswe"
      }
    ]
  },
  {
    id: "statusfinishsales",
    title: "Status Finish Sales",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "statusfinishsales1",
        title: "Terminated",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesop"
      },
      {
        id: "reportstatusfinishsales2",
        title: "Cancelled",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesca"
      },
      {
        id: "reportstatusfinishsales3",
        title: "Actived",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesac"
      },
    ]
  },
  {
    id: "statusfinishsalesmonth",
    title: "Report Months",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "statusfinishsales1",
        title: "List",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesmonth"
      },
    ]
  },
  {
    id: "salesOthers",
    title: "Sales Others",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "InProccessing",
        title: "In Proccessing",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/InProcessingOther"
      },
      {
        id: "Pending",
        title: "Pending",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/PendingOther"
      },
      {
        id: "ClosedOther",
        title: "Closed",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/ClosedOther"
      },
      {
        id: "Delete",
        title: "Delete",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/DeleteOther"
      },
      {
        id: "Renovation",
        title: "Renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/RenewOther"
      },
    ]
  },
  {
    id: "renovationsalesreport",
    title: "Sales Renovation",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "renovationsalesreportprenew",
        title: "In Proccessing renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/renovationprenewappointmentauditor"
      },
      /* {
        id: "renovationsalesreportclosed",
        title: "Closed renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/renovationsalesreportclosedproccesor"
      }, */
      /*  {
        id: "renovationsalesreportcancelled",
        title: "Cancelled renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/renovationsalesreportcancelled"
      }, */
    ]
  },
]

const navigationSuperadmin = [
  {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "formconfig",
    title: "Setting",
    type: "collapse",
    icon: <FaCog size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formugoals",
        title: "Goals",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/goalsform"
      },
    ]
  },
  {
    id: "formuser",
    title: "Users",
    type: "collapse",
    icon: <FaUserCog size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formuser1",
        title: "User Form",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/userform"
      },
      {
        id: "formuser2",
        title: "Report",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportlogin"
      }
    ]
  },
  {
    id: "formomabacare",
    title: "Obama care",
    type: "collapse",
    icon: <FaListUl size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesreport1",
        title: "New sale",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/newappointmentsell"
      }
    ]
  },
  {
    id: "formagency",
    title: "Agency",
    type: "collapse",
    icon: <FaRegBuilding size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formagency1",
        title: "Agency registration",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/formagency"
      }
    ]
  },
  {
    id: "formagent",
    title: "Agent",
    type: "collapse",
    icon: <FaUser size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formagent1",
        title: "Register",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/formagent"
      },
      {
        id: "salesMonth",
        title: "Months",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/salesMonth"
      }
    ]
  },
  {
    id: "upload",
    title: "Upload",
    type: "collapse",
    icon: <FaFileUpload size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "uploadagent",
        title: "File agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/uploadagent"
      },
      {
        id: "agentupload",
        title: "Agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/agentupload"
      }
    ]
  },
  {
    id: "salesreport",
    title: "Sales Report",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "reportappointmentsales1",
        title: "Sales in processing",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/prenewappointmentsell"
      },
      {
        id: "salesreport2",
        title: "Pending sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsell"
      },
      {
        id: "salesreport3",
        title: "Closed sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/appointmentsale"
      },
      {
        id: "salesreport4",
        title: "Delete sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/deleteAppointmentsell"
      },
      {
        id: "salesreport5",
        title: "Recovery sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/recoveryappointmentsalesproccesor"
      },
    ]
  },
  {
    id: "reportbackofice",
    title: "Report BackOffice",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "reportSpeakEnglish",
        title: "Speak English",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/speak"
      },
      {
        id: "reportOver65YearsOld",
        title: "Over 65 years old",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/over"
      },
      {
        id: "reportNotInterested",
        title: "Not interested",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/notinterested"
      },
      {
        id: "reportIncorrectNumbers",
        title: "Incorrect or invalid numbers",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/incorrectnumbers"
      },
      {
        id: "reportHasworkinsurance",
        title: "Has Work Insurance",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/hasworinsurance"
      },
      {
        id: "reportNoanswer",
        title: "No answer",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/noanswe"
      }
    ]
  },
  {
    id: "statusfinishsales",
    title: "Status Finish Sales",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesreport3",
        title: "Pending for status",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesempty"
      },
      {
        id: "statusfinishsales1",
        title: "Terminated",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesop"
      },
      {
        id: "reportstatusfinishsales2",
        title: "Cancelled",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesca"
      },
      {
        id: "reportstatusfinishsales3",
        title: "Actived",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesac"
      },
    ]
  },
  {
    id: "statusfinishsalesmonth",
    title: "Report Months",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "statusfinishsales1",
        title: "List",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesmonth"
      },
    ]
  },
  {
    id: "salesOthers",
    title: "Sales Others",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "Pending",
        title: "Pending",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/PendingOther"
      },
      {
        id: "ClosedOther",
        title: "Closed",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/ClosedOther"
      },
    ]
  },
]

const navigatorSupervisor = [
  {
    id: "formomabacareS",
    title: "Obama care",
    type: "collapse",
    icon: <FaListUl size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesreport1S",
        title: "New sale",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/newappointmentsell"
      }
    ]
  },
  {
    id: "salesreport",
    title: "My Sales Report",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesreportCS1",
        title: "Draft sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsellDraft"//Ventas propias en borrador por el usuario
      },
      {
        id: "salesreportCS2",
        title: "Pending sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsellCS"//Ventas propias pendientes por el usuario
      },
      {
        id: "salesreport3",
        title: "Closed Sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportsalesbyagents"
      },
    ]
  },
  {
    id: "generalsalesreport",
    title: "Sales Reports",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "generalsalesreportclosed",
        title: "Closed",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/generalsalesreportclosed"
      },
      /* {
        id: "generalsalesreportdelete",
        title: "Delete",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/generalsalesreportdelete"
      }, */
      {
        id: "generalsalesreportcancelled",
        title: "Cancelled",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/generalsalesreportcancelled"
      },
    ]
  },
  {
    id: "upload",
    title: "Upload",
    type: "collapse",
    icon: <FaFileUpload size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "uploadagent",
        title: "File agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/uploadagent"
      }
    ]
  },
  {
    id: "upload2",
    title: "Phone list",
    type: "collapse",
    icon: <FaPhoneAlt size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "agentupload",
        title: "Agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/agentupload"
      },
      {
        id: "reportNodocument",
        title: "No Answer",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/noanswerselles"
      }
    ]
  },
]

const navigatorSupport = [
  {
    id: "formuser",
    title: "Users",
    type: "collapse",
    icon: <FaUserCog size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "formuser1",
        title: "User Form",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/userform"
      },
      {
        id: "formuser2",
        title: "Report",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportlogin"
      }
    ]
  },
  {
    id: "upload",
    title: "Upload",
    type: "collapse",
    icon: <FaFileUpload size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "uploadagent",
        title: "File agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/uploadagent"
      },
      {
        id: "agentupload",
        title: "Agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/agentupload"
      },
      {
        id: "renewupload",
        title: "Renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/renewupload"
      }
    ]
  },
  {
    id: "reportbackofice",
    title: "Report BackOffice",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "reportSpeakEnglish",
        title: "Speak English",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/speak"
      },
      {
        id: "reportOver65YearsOld",
        title: "Over 65 years old",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/over"
      },
      {
        id: "reportNotInterested",
        title: "Not interested",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/notinterested"
      },
      {
        id: "reportIncorrectNumbers",
        title: "Incorrect or invalid numbers",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/incorrectnumbers"
      },
      {
        id: "reportHasworkinsurance",
        title: "Has Work Insurance",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/hasworinsurance"
      },
      {
        id: "reportNoanswer",
        title: "No answer",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/noanswe"
      }
    ]
  },
]

const navigatorAgent2 = [
  {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "formomabacare",
    title: "Obama care",
    type: "collapse",
    icon: <FaListUl size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesreport1",
        title: "New sale",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/newappointmentsell"
      }
    ]
  },
  {
    id: "salesreport",
    title: "Sales",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      /* {
        id: "reportappointmentsales1",
        title: "Sales in processing",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/prenewappointmentsell"
      }, */
      {
        id: "salesreport2",
        title: "Draft Sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/draftAppointmentsell"
      },
      {
        id: "salesreport4",
        title: "Processing Sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/processingsalesbyagents"
      },
      {
        id: "salesreport5",
        title: "Pending Sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsell"
      },
      {
        id: "salesreport3",
        title: "Closed Sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportsalesbyagents"
      },
      {
        id: "salesreport6",
        title: "Renovation Sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/renovationsell"
      },
    ]
  },
  {
    id: "upload",
    title: "Phone list",
    type: "collapse",
    icon: <FaPhoneAlt size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "agentupload",
        title: "Agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/agentupload"
      },
      {
        id: "reportNodocument",
        title: "No Answer",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/noanswerselles"
      }
    ]
  }
]

const navigatorProcessor = [
  {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "salesreport",
    title: "Sales Report",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "reportappointmentsales1",
        title: "In proccessing",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/prenewappointmentsell"
      },
      {
        id: "salesreport2",
        title: "Pending sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsell"
      },
      {
        id: "salesreport3",
        title: "Closed sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/closedappointmentsalesproccesor"
      },
      {
        id: "salesreport4",
        title: "Delete sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/deleteAppointmentsell"
      },
      {
        id: "salesreport5",
        title: "Recovery sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/recoveryappointmentsalesproccesor"
      },
    ]
  },
  {
    id: "salesOthers",
    title: "Sales Others",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "InProccessing",
        title: "In Proccessing",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/InProcessingOther"
      },
      {
        id: "Pending",
        title: "Pending",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/PendingOther"
      },
      {
        id: "ClosedOther",
        title: "Closed",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/ClosedOther"
      },
      {
        id: "Delete",
        title: "Delete",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/DeleteOther"
      },
      {
        id: "Renovation",
        title: "Renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/RenewOther"
      },
    ]
  },
  {
    id: "renovationsalesreport",
    title: "Sales Renovation",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "renovationsalesreportprenew",
        title: "In Proccessing renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/renovationsalesreportprenew"
      },
      {
        id: "renovationsalesreportclosed",
        title: "Closed renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/renovationsalesreportclosedproccesor"
      },
      /*  {
        id: "renovationsalesreportcancelled",
        title: "Cancelled renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/renovationsalesreportcancelled"
      }, */
    ]
  },
]

const navigatorProcessorO = [
  {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "salesreport",
    title: "Sales Report",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "reportappointmentsales1",
        title: "Sales in processing",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/prenewappointmentsell"
      },
      {
        id: "salesreport2",
        title: "Pending sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsell"
      },
      {
        id: "salesreport3",
        title: "Closed sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/closedappointmentsalesproccesor"
      },
      {
        id: "salesreport4",
        title: "Delete sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/deleteAppointmentsell"
      },
      {
        id: "salesreport5",
        title: "Recovery sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/recoveryappointmentsalesproccesor"
      },
    ]
  },
  {
    id: "salesOthers",
    title: "Sales Others",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "InProccessing",
        title: "In Proccessing",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/InProcessingOther"
      },
      {
        id: "Pending",
        title: "Pending",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/PendingOther"
      },
      {
        id: "ClosedOther",
        title: "Closed",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/ClosedOther"
      },
      {
        id: "Delete",
        title: "Delete",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/DeleteOther"
      },
      {
        id: "Renovation",
        title: "Renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/RenewOther"
      },
    ]
  },
]

const navigatorCustomerServiceLeader = [
  {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "formagent",
    title: "Agent",
    type: "collapse",
    icon: <FaUser size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesMonth",
        title: "Months",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/salesMonth"
      }
    ]
  },
  {
    id: "salesreport",
    title: "Sales Report",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "reportappointmentsales1",
        title: "Sales in processing",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/prenewappointmentsell"
      },
      {
        id: "salesreport3",
        title: "Closed sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/appointmentsale"
      },
      {
        id: "salesreport4",
        title: "Delete sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/deleteAppointmentsell"
      },
    ]
  },
  {
    id: "statusfinishsales",
    title: "Status Finish Sales",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesreport3",
        title: "Pending for status",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesempty"
      },
      {
        id: "statusfinishsales1",
        title: "Termined",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesop"
      },
      {
        id: "reportstatusfinishsales2",
        title: "Cancelled",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesca"
      },
      {
        id: "reportstatusfinishsales3",
        title: "Active",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesac"
      },
    ]
  },
  {
    id: "statusfinishsalesmonth",
    title: "Report Months",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "statusfinishsales1",
        title: "List",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportstatusfinishsalesmonth"
      },
    ]
  },
  {
    id: "salesOthers",
    title: "Sales Others",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "InProccessing",
        title: "In Proccessing",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/InProcessingOther"
      },
      {
        id: "Pending",
        title: "Pending",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/PendingOther"
      },
      {
        id: "ClosedOther",
        title: "Closed",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/ClosedOther"
      },
      {
        id: "Delete",
        title: "Delete",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/DeleteOther"
      },
      {
        id: "Renovation",
        title: "Renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/RenewOther"
      },
    ]
  },
  {
    id: "renovationsalesreport",
    title: "Sales Renovation",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "renovationsalesreportprenew",
        title: "In Proccessing renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/renovationsalesreportprenew"
      },
      {
        id: "renovationsalesreportclosed",
        title: "Closed renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/renovationsalesreportclosedproccesor"
      },
      /*  {
        id: "renovationsalesreportcancelled",
        title: "Cancelled renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/renovationsalesreportcancelled"
      }, */
    ]
  },
]

const navigatorCustomerService = [
  {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "formomabacare",
    title: "Obama care",
    type: "collapse",
    icon: <FaListUl size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesreport1",
        title: "New sale",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/newappointmentsell"
      }
    ]
  },
  {
    id: "salesreport",
    title: "My Sales Report",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesreportCS1",
        title: "Draft sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsellDraft"//Ventas propias en borrador por el usuario
      },
      {
        id: "salesreportCS2",
        title: "Pending sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsellCS"//Ventas propias pendientes por el usuario
      },
      {
        id: "salesreportCS3",
        title: "Closed sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/appointmentsaleCS"//Ventas propias vendidas por el usuario
      },
    ]
  },
  {
    id: "salesreport_1",
    title: "Sales Report",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "reportappointmentsales1",
        title: "Sales in processing",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/prenewappointmentsell"
      },
      {
        id: "salesreport2",
        title: "Pending sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsell"
      },
      {
        id: "salesreport3",
        title: "Closed sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/appointmentsale"
      },
    ]
  },
  {
    id: "salesOthers",
    title: "Sales Others",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "InProccessing",
        title: "In Proccessing",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/InProcessingOther"
      },
      {
        id: "Pending",
        title: "Pending",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/PendingOther"
      },
      {
        id: "ClosedOther",
        title: "Closed",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/ClosedOther"
      },
      {
        id: "Delete",
        title: "Delete",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/DeleteOther"
      },
      {
        id: "Renovation",
        title: "Renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/RenewOther"
      },
    ]
  },
  {
    id: "renovationsalesreport",
    title: "Sales Renovation",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "renovationsalesreportprenew",
        title: "In Proccessing renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/renovationsalesreportprenew"
      },
      {
        id: "renovationsalesreportclosed",
        title: "Closed renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/renovationsalesreportclosedproccesor"
      },
      /*  {
        id: "renovationsalesreportcancelled",
        title: "Cancelled renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/renovationsalesreportcancelled"
      }, */
    ]
  },
]

const navigatorQualityLeader = [
  {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "formomabacareS",
    title: "Obama care",
    type: "collapse",
    icon: <FaListUl size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesreport1S",
        title: "New sale",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/newappointmentsell"
      }
    ]
  },
  {
    id: "salesreport",
    title: "My Sales Report",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesreportCS1",
        title: "Draft sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsellDraft"//Ventas propias en borrador por el usuario
      },
      {
        id: "salesreportCS2",
        title: "Pending sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsellCS"//Ventas propias pendientes por el usuario
      },
      {
        id: "salesreport3",
        title: "Closed Sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/reportsalesbyagents"
      },
    ]
  },
  {
    id: "generalsalesreport",
    title: "Sales Reports",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "generalsalesreportprenew",
        title: "Prenew",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/generalsalesreportprenew"
      },
      {
        id: "generalsalesreportclosed",
        title: "Closed",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/generalsalesreportclosed"
      },
      /* {
        id: "generalsalesreportdelete",
        title: "Delete",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/generalsalesreportdelete"
      }, */
      {
        id: "generalsalesreportcancelled",
        title: "Cancelled",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/generalsalesreportcancelled"
      },
    ]
  },
  {
    id: "renovationsalesreport",
    title: "Sales Renovation",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "renovationsalesreportprenew",
        title: "Prenew renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/renovationsalesreportprenew"
      },
      {
        id: "renovationsalesreportclosed",
        title: "Closed renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/renovationsalesreportclosed"
      },
      /*  {
        id: "renovationsalesreportcancelled",
        title: "Cancelled renovation",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/renovationsalesreportcancelled"
      }, */
    ]
  },
  /* {
    id: "upload",
    title: "Upload",
    type: "collapse",
    icon: <FaFileUpload size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "uploadagent",
        title: "File agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/uploadagent"
      }
    ]
  },
  {
    id: "upload2",
    title: "Phone list",
    type: "collapse",
    icon: <FaPhoneAlt size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "agentupload",
        title: "Agent",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/agentupload"
      },
      {
        id: "reportNodocument",
        title: "No Answer",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/noanswerselles"
      }
    ]
  },*/
]

const navigatorQualityAux = [
  {
    id: "home",
    title: "Homepage",
    type: "item",
    icon: <FaHome size={size} />,
    permissions: ["admin", "editor"],
    navLink: "/home"
  },
  {
    id: "formomabacare",
    title: "Obama care",
    type: "collapse",
    icon: <FaListUl size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesreport1",
        title: "New sale",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/newappointmentsell"
      }
    ]
  },
  {
    id: "salesreport",
    title: "My Sales Report",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "salesreportCS1",
        title: "Draft sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsellDraft"//Ventas propias en borrador por el usuario
      },
      {
        id: "salesreportCS2",
        title: "Pending sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/pendingAppointmentsellCS"//Ventas propias pendientes por el usuario
      },
      {
        id: "salesreportCS3",
        title: "Closed sales",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/appointmentsaleCS"//Ventas propias vendidas por el usuario
      },
    ]
  },
  {
    id: "generalsalesreport",
    title: "Sales Reports",
    type: "collapse",
    icon: <FaDatabase size={size} />,
    permissions: ["admin", "editor"],
    children: [
      {
        id: "generalsalesreportprenew",
        title: "Prenew",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/generalsalesreportprenew"
      },
      {
        id: "generalsalesreportclosed",
        title: "Closed",
        type: "item",
        icon: <FaCheck size={size} />,
        permissions: ["admin", "editor"],
        navLink: "/generalsalesreportclosed"
      },
    ]
  },
]


const navigationConfig =
  user?.profile === 7 ? navigationSuperadmin ://Superadmin
    user?.profile === 104 ? navigatorSupervisor ://Supervisor
      user?.profile === 106 ? navigatorSupport ://Support
        user?.profile === 118 ? navigatorAgent2 ://Agente
          user?.profile === 211 || user?.profile === 228 ? navigatorProcessor ://Processor
            user?.profile === 219 ? navigatorCustomerServiceLeader ://Customer Service Leader
              user?.profile === 227 ? navigatorCustomerService ://Customer Service
                user?.profile === 238 ? navigatorQualityLeader ://Quality Leader
                  user?.profile === 239 ? navigatorQualityAux ://Quality Aux
                    user?.profile === 251 ? navigationSuperadminTop ://SuperadminTop
                      user?.profile === 255 ? navigationAuditor ://Auditor
                        user?.profile === 256 ? navigatorProcessorO ://Auditor
          []

export default navigationConfig
